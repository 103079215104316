import http from "../http";
import axios from "axios";


/**
 * 分页查询采购计划管理查询
 */
export const factorySerch = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/search?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};


/**
 * 分页查询采购计划管理查询
 */
export const purchasegetstockup = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL+`allocation/v1/confirmingdodes`,
    data
  });
};


/**
 * 新增获取供应商下拉数据
 */
export const getsupplierName = (supplierId,supplierName) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SUPPLIER_URL+`supplier/v1/supplierCompanys/dict?supplierId=${supplierId}&supplierName=${supplierName}`
  });
};

/**
 * 新增采购计划管理信息获取uuid
 */
export const purchasegetuuid = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/getUUID`,
  });
};

/**
 * 编辑查看列表
 */
export const factorychildshow = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocationByThreeData/${id}`
  });
};
// export const factorychildshow = (id) => {
//   return http({
//     method: "get",
//     url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/${id}`
//   });
// };



/**
 * 删除子项展示table列表
 */
export const factorychilddel = (id) => {
  return http({
    method: "delete",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/allocation/${id}`,
  });
};


/**
 * 新增子项form列表
 */
export const factorychildadd = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL+ `allocation/v1/allocation`,
    data
  });
};


/**
 * 请求类型调取
 */
export const purchaserequesttype = () => {
//   let res ={}
//   res.data=[
//     {
//         "id": 623,
//         "rev": 1,
//         "createTime": "2021-01-13T03:26:59.147+0000",
//         "updateTime": "2021-01-13T03:26:59.147+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B099999",
//         "dictItemName": "世家宝-样本采购",
//         "dictItemOrder": 1,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 147,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010201",
//         "dictItemName": "以销定采-职业装",
//         "dictItemOrder": 2,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 612,
//         "rev": 1,
//         "createTime": "2020-10-26T04:00:39.133+0000",
//         "updateTime": "2020-10-26T04:00:39.133+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010206",
//         "dictItemName": "以销定采-职业装-面料生产",
//         "dictItemOrder": 3,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 149,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010401",
//         "dictItemName": "以销定采-订货大样",
//         "dictItemOrder": 4,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 140,
//         "rev": 1,
//         "createTime": "2020-10-19T03:42:17.000+0000",
//         "updateTime": "2020-10-19T03:42:20.000+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010501",
//         "dictItemName": "以销定采-订货大货",
//         "dictItemOrder": 5,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 148,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010301",
//         "dictItemName": "以销定采-零剪",
//         "dictItemOrder": 6,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 171,
//         "rev": 1,
//         "createTime": "2020-05-31T23:43:34.614+0000",
//         "updateTime": "2020-05-31T23:43:34.614+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010202",
//         "dictItemName": "以销定采-零剪-叫料",
//         "dictItemOrder": 7,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 611,
//         "rev": 1,
//         "createTime": "2020-10-26T03:59:33.314+0000",
//         "updateTime": "2020-10-26T03:59:33.314+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B010306",
//         "dictItemName": "以销定采-零剪-面料生产",
//         "dictItemOrder": 8,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 184,
//         "rev": 1,
//         "createTime": "2020-06-22T22:03:47.089+0000",
//         "updateTime": "2020-06-22T22:03:47.089+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B20305",
//         "dictItemName": "备货采购-新首-职业装",
//         "dictItemOrder": 9,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 185,
//         "rev": 1,
//         "createTime": "2020-06-22T22:04:27.451+0000",
//         "updateTime": "2020-06-22T22:04:27.451+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B20306",
//         "dictItemName": "备货采购-新首补单-职业装",
//         "dictItemOrder": 10,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 187,
//         "rev": 1,
//         "createTime": "2020-06-22T22:05:44.397+0000",
//         "updateTime": "2020-06-22T22:05:44.397+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B20308",
//         "dictItemName": "备货采购-新首补单-零剪",
//         "dictItemOrder": 11,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 186,
//         "rev": 1,
//         "createTime": "2020-06-22T22:05:05.803+0000",
//         "updateTime": "2020-06-22T22:05:05.803+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B20307",
//         "dictItemName": "备货采购-新首-零剪",
//         "dictItemOrder": 12,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 151,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020302",
//         "dictItemName": "备货采购-职业装",
//         "dictItemOrder": 13,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 153,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020203",
//         "dictItemName": "备货采购-职业装-坯布/纱线",
//         "dictItemOrder": 14,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 610,
//         "rev": 1,
//         "createTime": "2020-10-26T03:59:08.833+0000",
//         "updateTime": "2020-10-26T03:59:08.833+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020206",
//         "dictItemName": "备货采购-职业装-面料生产",
//         "dictItemOrder": 15,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 150,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020301",
//         "dictItemName": "备货采购-零剪",
//         "dictItemOrder": 16,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 608,
//         "rev": 1,
//         "createTime": "2020-10-23T02:31:45.536+0000",
//         "updateTime": "2020-10-23T02:31:45.536+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020304",
//         "dictItemName": "备货采购-零剪-世家宝",
//         "dictItemOrder": 17,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 154,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020303",
//         "dictItemName": "备货采购-零剪-坯布/纱线",
//         "dictItemOrder": 18,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 609,
//         "rev": 1,
//         "createTime": "2020-10-26T03:58:45.136+0000",
//         "updateTime": "2020-10-26T03:58:45.136+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020306",
//         "dictItemName": "备货采购-零剪-面料生产",
//         "dictItemOrder": 19,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 637,
//         "rev": 1,
//         "createTime": "2021-02-01T10:42:11.309+0000",
//         "updateTime": "2021-02-01T10:42:11.309+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020309",
//         "dictItemName": "开发大样-零剪",
//         "dictItemOrder": 20,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 644,
//         "rev": 1,
//         "createTime": "2021-10-29T06:57:46.888+0000",
//         "updateTime": "2021-10-29T06:57:46.888+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020310",
//         "dictItemName": "开发大样-职业装",
//         "dictItemOrder": 21,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 197,
//         "rev": 1,
//         "createTime": "2020-10-13T02:28:40.000+0000",
//         "updateTime": "2020-10-13T02:28:47.000+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B020305",
//         "dictItemName": "日常备货-集团",
//         "dictItemOrder": 22,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 155,
//         "rev": 1,
//         "createTime": "2020-03-18T03:26:37.515+0000",
//         "updateTime": "2020-03-18T03:26:37.515+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B07",
//         "dictItemName": "现货采购-原材料",
//         "dictItemOrder": 23,
//         "remark": "业务类型",
//         "dictItemLanguage": "CH"
//     },
//     {
//         "id": 201,
//         "rev": 1,
//         "createTime": "2020-08-18T04:29:52.942+0000",
//         "updateTime": "2020-08-18T04:29:52.942+0000",
//         "dictIndex": "purchaseType",
//         "dictItemValue": "B9901",
//         "dictItemName": "采购申请差异单",
//         "dictItemOrder": 24,
//         "remark": null,
//         "dictItemLanguage": "CH"
//     }
// ]
  // res.code = '0'
return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/dicts?dictIndex=purchaseType`
});
// return res 
};
/**
 * 新增父项form列表
 */
export const factoryparentadd = (id,confirmNumber,confirmTimeStr) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL+ `scm/v1/factoryconfirmitems/batch2?id=${id}&confirmNumber=${confirmNumber}&confirmTimeStr=${confirmTimeStr}`
  });
};

/**
 * 分页条查展示预收货清单（供应链）
 */
export const gylPrepareReceiptList = (currentPage, pageSize, data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlist/v1/queryPrePageByGyl?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};

/**
 * 根据id查询工厂调拨单与工厂调拨项
 */
export const searchPrepareReceiptItem = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlistitem/v1/searchPrepareReceiptListItem`,
    data
  });
};

/**
 * 编辑预收货清单项，添加批次号
 */
export const editPrepareReceiptItem = (data) => {
  return http({
    method: "put",
    url: process.env.VUE_APP_SCM_URL + `preparereceiptlistitem/v1/updatePrepareReceiptListItem`,
    data
  });
};

/**
 * 根据id下载附件
 */
export const downloadInvoicePackingFile = (id) => {
  return http({
    method: "get",
    responseType: 'arraybuffer',
    url: process.env.VUE_APP_INFRASTRUCTURE_URL + `/infrastructure/v1/attachments/downloadById?id=${id}`
  });
};


/**
   * 仓位类型调取
   */
export const warehousequesttype = () => {
  let res ={}
  res.data = [
    {"name": "集团仓","index": "A0"},
    {"name": "零剪仓","index": "B0"},
    {"name": "职业装仓","index": "C0"},
    {"name": "订货仓","index": "D0" }
  ]
  res.code='0'
// return http({
//     method: "get",
//     url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=VirtualInventory`
// });
return res 
};

/**
   * 库位类型调取(仅限库内调拨)
   */
export const subinventoryRollsType=(virtualInventoryId) => {
return http({
    method: "get",
    url: process.env.VUE_APP_VIRTUALINVENTORY_URL+ `virtualinventory/v1/virtualinventorys/queryWmsSubInventory/${virtualInventoryId}`
});
};

/**
   * 库位类型调取
 */
export const subinventoryquesttype=(virtualInventoryId) => {
    return http({
        method: "get",
        url: process.env.VUE_APP_VIRTUALINVENTORY_URL+ `virtualinventory/v1/virtualinventorys/subInventory/${virtualInventoryId}`
    });
};

/**
   * 调拨类型调取
   */
export const getallottype = () => {
  let res ={}
  res.data =  [
    {"name": "客订-工厂调拨","index": "A001"},
    {"name": "备货-工厂调拨","index": "A002"}
]
  res.code = '0'

// return http({
//     method: "get",
//     url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/infrastructure/v1/constants/dicts?dictIndex=TransferType`
// });
return res  
};

/**
 * 新增采购计划拉取客订数据管理信息
 */
export const purchasegetdata = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_OMS_URL+ `/saleorder/oms/saleorders/saleorderanditem`,
    data
  });
};

/**
 * 获取在库可用库存--类型工厂调拨
 */
export const purchasegetInventory = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL+ `allocation/v1/availableinventory`,
    data
  });
};

/**
 * 获取在库可用库存--类型库内调拨
 */
export const getInventory = (params) => {
  let data = {
    orderBy: [],
    query:
      [{ fieldName: "warehouseNo", opr: "eq", value: params.inventory },
       { fieldName: "materialNo", opr: "eq", value: params.materialCode },
       { fieldName: "storageNo", opr: "eq", value: params.subInventory },],
    subTotal:
      [{ subTotalBy: "availQuantity", subTotalOpr: "SUM" }],
    groupBy: [],
  }

 let result=  http({
    method: "post",
    url: process.env.VUE_APP_INVENTORY_URL + `materialInventoryView/v2/index?currentPage=1&pageSize=9999`,
    data
 });
 result._then = result.then
 result.then = (cb) => {
   result._then((res) => {
     cb(res)
   })
 }
 return result
};
export const purchasegetInventorylast = async (data) => {
  // return http({
  //   method: "post",
  //   url: process.env.VUE_APP_INVENTORY_URL + `recordinventory/v1/transactiondetails/availablestock`,
  //   data
  // });
  let res = await getInventory(data)
  let result = {
    data: res.data.records.length > 0 ? res.data.records[0].availQuantity : '',
    code: res.code,
    message: res.message,
  };
  return result
};


/**
 * 外侧提交审批
 */
export const factorychildoutsave = (id) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL+ `allocation/v1/submitallocationstobpm?id=${id}`,
  });
};

/**
 * 新增调拨计划拉取备货数据管理信息
 */
export const purchasegetdatastok = () => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SALEPREDICT_URL+ `salepredict/v1/transferPlanResults/factoryTransfer`
  });
};

/**
 * 查询所有物料建议调拨数量
 */
export const pullCustomerOrderRequest = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryAllTransfer`,
    data
  });
};

/**
 * 根据物料编号查询数据库是否存在此物料编号
 */
export const getmaterialCode = (materialCode) => {
  return http({
      method: "get",
      url: process.env.VUE_APP_MATERIAL_URL + `material/v1/materials/querytMaterialAndFinishedProduct?materialCode=${materialCode}`
  });
};

/**
   *根据物料编号查询计量单位
   */
export const getmaterialCodeUnit=(materialCode) => {
return http({
    method: "get",
    url: process.env.VUE_APP_MATERIAL_URL+ `material/v1/materials/purchasemeasurementunit?materialCode=${materialCode}`
});
};

/**
 *根据产品编号查询物料编号
  */
export const getproductCode=(productCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_PRODUCT_URL+ `product/v1/productbyproductcode/search?productCode=${productCode}`
  });
};

/**
   * 审批流程调取
   */
export const getapprovalOpinions=(flowUuid) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_INFRASTRUCTURE_URL+ `/bpm/v1/approvalOpinions?flowUuid=${flowUuid}`
  });
};

/**
 *根据物料编号查询确认书列表
 */
export const getConfirmNumbersByMC = (materialCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `scm/v1/factoryconfirmitems/searchConfirmNumberByMaterialCode?materialCode=${materialCode}`
  });
};

/**
 * 根据物料编号查询产品编号
 */
export const getProductCodeByMC = (materialCode, productName, productCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_PRODUCT_URL + `productmaterial/v1/productmaterial/dist?materialCode=${materialCode}&productName=${productName}&productCode=${productCode}`
  });
};

/**
 * 根据确认书号查询调拨数量
 */
export const getAllocationQuantityByCN = (materialCode, confirmNumber) => {
  return http({
    method: "get",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: process.env.VUE_APP_SCM_URL + `cargoreadylist/v1/queryAllocationQuantity?materialCode=${materialCode}&confirmNumber=${encodeURIComponent(confirmNumber)}`
  });
};

/**
 * 根据入库仓位查询建议调拨数量
 */
export const getTPQuantityByInventory = (materialCode, inventory) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_SCM_URL + `allocation/v1/queryTransferQuantity?materialCode=${materialCode}&inventory=${inventory}`
  });
};

/**
 * 根据物料编号查询数据库是否存在此物料编号
 */
export const existMaterialCode = (materialCode) => {
  return http({
    method: "get",
    url: process.env.VUE_APP_MATERIAL_URL + `material/v1/materials/querytMaterialAndFinishedProduct?materialCode=${materialCode}`
  });
};
// export const existMaterialCode = (materialCode) => {
//   return http({
//     method: "get",
//     url: process.env.VUE_APP_MATERIAL_URL + `material/v1/materials/selectMaterialByMaterialCode?materialCode=${materialCode}`
//   });
// };

/**
 * 查询建议调拨数量
 */
export const searchTransferPlanQuantity = (data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `plan/v1/queryTransfer`,
    data
  });
};